import React, { useEffect, useRef, useState } from "react";
import { Modal, ToastContainer } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resume_builder1 } from "../../../../assets/images/images";
import { createJobSeeker } from "../../../../redux/actions/jobSeekerActions";
import "../../../Login/Login.css";
import PostJobModal from "../../RecruiterDashboard/CommonRecruiterComponant/PostJobModal";
import "../ResumeBuilder/ResumeBuilder.css";
import { signOut } from "../../../../redux/actions/authActions";
import DashboardHeader from "../../../../components/CommonComponents/DashboardHeader";
import useFileUpload from "../../../../hooks/useFileUpload";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ResumeBuilder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const job_seeker_id = useSelector(
    (state) => state.auth.user?.job_seeker_id ?? state.auth?.jobseeker_id
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [showFileModal, setShowFileModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const { uploadStatus, uploadFile } = useFileUpload();
  const [pageNumber, setPageNumber] = useState(1); // Set to display the first page
  const pdfPreviewRef = useRef(null);
  const [fileInputValue, setFileInputValue] = useState("");
  const [selectedFileType, setSelectedFileType] = useState("");

  const token = useSelector((state) => state.auth.token);
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const fileSize = file.size;
    const minSize = 1000; // Minimum file size: 1 KB (in bytes)
    const maxSize = 5242880; // Maximum file size: 5 MB (in bytes)

    if (fileSize < minSize || fileSize > maxSize) {
      let errorMessage = "";
      if (fileSize < minSize) {
        errorMessage = `File size must be above 1 KB. Current file size is ${(
          fileSize / 1024
        ).toFixed(2)} KB.`;
      } else {
        errorMessage = `File size must be below 5 MB. Current file size is ${(
          fileSize / 1048576
        ).toFixed(2)} MB.`;
      }
      toast.error(errorMessage);
      return;
    }
    const allowedTypes = [
      "application/pdf",
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // MIME type for .docx
    ];

    if (file && allowedTypes.includes(file.type)) {
      setSelectedFile(file);
      setShowFileModal(true);

      // setShowVerificationModal(false);

      const fileUrl = await uploadFile(file, token);
      // const fileUrl = uploadResults.get(file) || uploadResults[file];

      if (fileUrl) {
        setSelectedFileType(file.type);
        setShowFileModal(true);
        setPdfUrl(fileUrl);
        try {
          await dispatch(createJobSeeker(token, fileUrl, ["English", "Hindi"]));
          if (
            file.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) {
            toast.success("Your resume is uploaded successfully");
          } else {
            toast.success(
              `${file.type
                .split("/")[1]
                .toUpperCase()} file selected and uploaded successfully`
            );
          }
          setShowFileModal(false);
          navigate("/confirm-information", { replace: true });
          setFileInputValue("");
        } catch (error) {
          setShowFileModal(false);
        }
      }
    } else {
      toast.error("Only PDF, DOCX, DOC, JPEG, JPG, and PNG files are allowed");
    }
  };

  useEffect(() => {
    setPdfUrl(selectedFile);
    if (job_seeker_id) {
      navigate("/applicant-dashboard", { replace: true });
    }
  }, []);

  const handleBack = () => {
    dispatch(signOut());
  };

  return (
    <>
      <ToastContainer />
      <div id="recaptcha-container"></div>
      <section className="resume_builder_sec">
        <div className="">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="container resume-builder-header-back">
                <DashboardHeader title="" backButtonLink={handleBack} />
              </div>
              <div className="resume_builder_sec_inner">
                <div className="resume_builder_sec_inner_left">
                  <img src={resume_builder1} alt="" />
                </div>
                <div>
                  <div className="resume_builder_sec_inner_right">
                    <h6>AI Resume Enhancer</h6>
                    <p>
                      Optimize your job search and AI interviews <br />
                      with AI-enhanced resumes.
                    </p>
                    <div className="upload_resume_btn scanning_page">
                      <label for="myfile">Upload Resume</label>
                      <input
                        type="file"
                        id="myfile"
                        name="myfile"
                        value={fileInputValue}
                        onChange={handleFileChange}
                      />
                    </div>
                    <hr className="spacer10px" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={showFileModal}
        onHide={() => setShowFileModal(false)}
        size="lg" // or you can leave it empty for default size
        className="pdf_view_scan_modal scan"
        centered
      >
        <Modal.Body
          className={
            selectedFileType ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ? "b-gg1"
              : "b-gg fingerprint"
          }
          style={{
            padding: "20px",
            // overflowY: "auto", // Changed to auto to allow scrolling
            // overflowX: "hidden",
          }}
        >
          <div
            ref={pdfPreviewRef}
            className="file_preview"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            {pdfUrl &&
              (selectedFileType.includes("image/") ? (
                <img
                  src={pdfUrl}
                  alt="Preview"
                  style={{ width: "100%", height: "auto" }}
                />
              ) : selectedFileType === "application/pdf" ? (
                <Document
                  file={pdfUrl}
                  onLoadSuccess={({ numPages }) => {
                    setPageNumber(1); // Adjust as necessary
                  }}
                >
                  <Page
                    pageNumber={pageNumber}
                    width={
                      pdfPreviewRef.current
                        ? pdfPreviewRef.current.offsetWidth - 40
                        : 600
                    }
                  />
                </Document>
              ) : selectedFileType ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                <div>
                  <PostJobModal name="doc" />
                </div>
              ) : (
                <div>Unsupported file format.</div>
              ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ResumeBuilder;
