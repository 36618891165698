import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  dash_black,
  dash_blue,
  linkedin,
  mail_icn,
  msg_black,
  msg_blue,
  my_jobs_black,
  my_jobs_blue,
  submenu,
  submenufill,
  wp_icn,
} from "../../../assets/images/images";
import { Navbar } from "../../../components/DashboardComponents/Navbar/Navbar";
import { usePricingModal } from "../../../contexts/PricingModalContext";
import { loadSubscriptions } from "../../../redux/actions/subscriptionActions";
import DashboardSideBar from "../DashboardSideBar/DashboardSideBar";
import "./Dashboard.css";
import DashboardMain from "./DashboardMain/DashboardMain";
import JobDetails from "./JobDetails/JobDetails";
import Monitization from "./Monitization/MonitizationPage";
import PricingModal from "./Monitization/components/PricingModal";
import MyCompany from "./MyCompany/MyCompany";
import MyJobs from "./MyJobs/MyJobs";
import ViewJobsData from "./MyJobs/ViewJobsData";
import ViewDemoJob from "./MyJobs/ViewDemoJob";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const Dashboard = () => {
  const jobDetails = useSelector((state) => state?.job?.details);
  const dispatch = useDispatch();
  const { isPricingModalOpen, hidePricingModal } = usePricingModal();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (location.state?.showShareJobModal) {
      setShowModal(true);
      // Clear the state to prevent. showing the modal again if the user navigates back
      window.history.replaceState({}, document.title);
    }
  }, [location.state]);

  const handleClose = () => {
    setShowModal(false);
  };
  const tabList = [
    {
      key: "Dashboard",
      route: "",
      icon: dash_black,
      activeIcon: dash_blue,
      component: DashboardMain,
      active: true,
    },
    {
      key: "My Jobs",
      route: "my-jobs/*",
      icon: my_jobs_black,
      activeIcon: my_jobs_blue,
      component: MyJobs,
      active: true,
    },
    // {
    //   key: "Messages",
    //   route: "messages",
    //   icon: app_black,
    //   activeIcon: app_blue,
    //   component: Messages,
    // },
    {
      key: "My Company",
      route: "my-company",
      icon: msg_black,
      activeIcon: msg_blue,
      component: MyCompany,
      active: true,
    },
    // {
    //   key: "Settings",
    //   route: "settings",
    //   icon: set_black,
    //   activeIcon: set_blue,
    //   component: Settings,
    // },
    {
      key: "Subscription",
      route: "subscription",
      icon: submenu,
      activeIcon: submenufill,
      component: Monitization,
      active: true,
    },
  ];

  useEffect(() => {
    dispatch(loadSubscriptions());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <section className="company_details_sec_new">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="company_details_sec_new_inner">
                {/* Dashboard Left Bar */}
                <DashboardSideBar tabList={tabList} />

                <div className="comp_det_rigt_main tab-content">
                  <Routes>
                    {tabList.map((tab, index) =>
                      tab.active ? (
                        <Route
                          key={index}
                          path={tab.route}
                          element={<tab.component />}
                        />
                      ) : null
                    )}
                    <Route
                      path="my-jobs/:jobType/:jobId/view-jobs-data"
                      element={<ViewJobsData />}
                    />
                    <Route
                      path="my-jobs/demo/:jobId/view-jobs-data"
                      element={<ViewDemoJob />}
                    />
                    <Route
                      path="my-jobs/:jobType/:jobId/edit"
                      element={<JobDetails />}
                    />
                  </Routes>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Pricing Modal start---------------------------------------- */}
      <PricingModal show={isPricingModalOpen} onHide={hidePricingModal} />
      {/* <div className="ResposiveContainer">
        <ResposiveContainer />
      </div> */}
      <Modal
        show={showModal}
        onHide={handleClose}
        className="share_prof_sec_mod"
        size="xl"
      >
        <div className="share_prof_sec_mod_inner">
          {/* onClick={handleClose} */}
          <button className="close_icn_new_sh" onClick={handleClose}>
            <i className="fas fa-times"></i>
          </button>
          <h6>Share</h6>
          <ul>
            <li>
              <a
                href={`https://api.whatsapp.com/send?text=Check out this job! ${encodeURIComponent(
                  `${window.location.origin}/jobs/${location.state?.slug}`
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={wp_icn} alt="whatsapp" />
              </a>
            </li>
            <li>
              <a
                href={`mailto:?subject=Check out this job!&body=Check out this job at ${encodeURIComponent(
                  `${window.location.origin}/jobs/${location.state?.slug}`
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={mail_icn} alt="gmail" />
              </a>
            </li>
            <li>
              <a
                href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                  `${window.location.origin}/jobs/${location.state?.slug}`
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedin} alt="linkedin" />
              </a>
            </li>
          </ul>
          <div className="copy_link_sec">
            <label>Or copy link</label>
            <div className="copy_link_sec_input">
              <input
                type="text"
                readOnly
                value={
                  `${window.location.origin}/jobs/${location.state?.slug}` ||
                  "example.com/share-link"
                }
              />
              <button
                onClick={() => {
                  navigator.clipboard
                    .writeText(
                      `${window.location.origin}/jobs/${location.state?.slug}` ||
                        "example.com/share-link"
                    )
                    .then(
                      () => {
                        toast.success("Link copied to clipboard!");
                      },
                      () => {
                        toast.error("Failed to copy the link.");
                      }
                    );
                }}
              >
                Copy
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Dashboard;
