import { useEffect, useState } from "react";

import { Table, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Credit, eye_icon } from "../../../../assets/images/images";
import {
  fetchJobApplicants,
  fetchRecentJobsList,
} from "../../../../redux/actions/jobActions";
import { resetCompany } from "../../../../redux/reducers/companyReducer";
import { resetJobDraftState } from "../../../../redux/reducers/jobReducer";
import { zeroCreditError } from "../../../../utils/constants";
import { formatDate } from "../../../../utils/utils";
import SkeletonLoader from "../CommonRecruiterComponant/SkeletonLoader";
import "../MyJobs/MyJobs.css";
import { InfoCircleOutlined } from "@ant-design/icons";
import TableSpan from "../../../../components/CommonComponents/TableSpan";

const RecentJobsTable = ({ activeTab }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const recentJobs = useSelector((state) => state.job.recentJobsList);
  const token = useSelector((state) => state.auth.token);
  const isLoading = useSelector((state) => state.job.loading);
  const [currentPage, setCurrentPage] = useState(1);
  const [technicalSkillsRating, setTechnicalSkillsRating] = useState(null);
  const [problemSolvingRating, setProblemSolvingRating] = useState(null);
  const [communicationRating, setCommunicationRating] = useState(null);
  const [behavioralSkillsRating, setBehavioralSkillsRating] = useState(null);
  const [overallRating, setOverAllRating] = useState(null);
  const [maxValue, setMaxValue] = useState(null);
  const [minValue, setMinValue] = useState(null);

  const companyDetails = useSelector((state) => state.company.details);
  const credits = companyDetails?.company_credits ?? 0;

  const handleViewJob = (jobId) => {
    dispatch(
      fetchJobApplicants(
        token,
        jobId,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        overallRating,
        technicalSkillsRating,
        problemSolvingRating,
        communicationRating,
        behavioralSkillsRating,
        minValue,
        maxValue,
        "",
        "",
        currentPage
      )
    );
    navigate(`/dashboard/my-jobs/${activeTab}/${jobId}/view-jobs-data`);
  };

  const handlePageChange = () => {
    setCurrentPage(1);
  };

  const postJob = () => {
    if (!companyDetails?.id) {
      dispatch(resetCompany());
      navigate("/company-details", { state: { isEmpty: true } });
    } else if (credits === 0) {
      toast.error(zeroCreditError);
    } else {
      dispatch(resetJobDraftState());
      navigate("/dashboard/job-details");
    }
  };

  useEffect(() => {
    dispatch(fetchRecentJobsList(token));
  }, [dispatch]);

  const columns = [
    {
      title: "Job Title",
      dataIndex: "title",
      key: "title",
      render: (text) => <div data-th="Job Title">{text}</div>,
    },
    {
      title: "Published Date",
      dataIndex: "published_date",
      key: "published_date",
      render: (text) => <div data-th="Publish Date">{formatDate(text)}</div>,
    },
    {
      title: "Applicants",
      dataIndex: "applicants_count",
      key: "applicants_count",
      render: (text) =>
        text && text !== null ? <div data-th="Applicants">{text}</div> : <>-</>,
    },
    {
      title: "Interviewed",
      dataIndex: "ai_interviewed_count",
      key: "ai_interviewed_count",
      render: (text) =>
        text && text !== null ? (
          <div data-th="AI Interviewed">{text}</div>
        ) : (
          <>-</>
        ),
    },
    {
      title: "Shortlisted",
      key: "ai_shortlisted_count",
      dataIndex: "ai_shortlisted_count",
      render: (text) =>
        text && text !== null ? (
          <div data-th="AI Shortlisted">{text}</div>
        ) : (
          <>-</>
        ),
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Credits{" "}
          <Tooltip
            title={
              <div style={{ textAlign: "center" }}>
                Displays the remaining credits left for interviews in this job
                posting.
              </div>
            }
            overlayStyle={{ textAlign: "center" }}
          >
            <InfoCircleOutlined style={{ marginLeft: 5 }} />
          </Tooltip>
        </div>
      ),
      dataIndex: "credits_left",
      key: "credits_left",
      render: (text) =>
        text && text !== null ? (
          <div data-th="Remaining Credits">
            <img
              src={Credit}
              alt=""
              style={{ width: "20px", height: "20px", marginRight: "5px" }}
            />{" "}
            {text}
          </div>
        ) : (
          <>-</>
        ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => (
        <div data-th="Status">
          <TableSpan status={status} />
        </div>
      ),
    },
    {
      title: "View Details",
      dataIndex: "view",
      key: "view",
      render: (text, record) => (
        <a onClick={() => handleViewJob(record.id)}>
          <img src={eye_icon} alt="eye_icon" className="eye_icon" />
          View
        </a>
      ),
    },
  ];

  const jobsWithKeys = recentJobs.map((job) => ({
    ...job,
    key: job.id, // or another unique identifier
  }));

  if (!jobsWithKeys) {
    return <div>Loading...</div>;
  }

  if (isLoading) {
    return (
      <SkeletonLoader
        includeTable={true}
        excludeDetails={true}
        tableRows={5}
        columnWidths={["30%", "20%", "15%", "15%", "10%", "10%"]}
      />
    );
  }
  return (
    <>
      <div>
        {jobsWithKeys && jobsWithKeys.length > 0 ? (
          <>
            <Table
              columns={columns}
              dataSource={jobsWithKeys}
              pagination={false}
              onChange={handlePageChange}
            />
          </>
        ) : (
          <div className="add_com_det_sec">
            <p>Get started with posting the job</p>
            <div className="btn_comp_sec">
              <button onClick={postJob} className="post_blue_btn">
                <span>
                  <i className="fas fa-plus"></i>
                </span>
                Post a Job
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RecentJobsTable;
