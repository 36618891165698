import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Modal as AntModal,
  Button,
  Checkbox,
  Form,
  Input,
  Modal as PopUp,
  Radio,
  Upload,
} from "antd";
import axios from "axios";
import { marked } from "marked";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Oval, ThreeCircles } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL, websocketUrl } from "../../../../api/instance";
import { sum_img } from "../../../../assets/images/images";
import DashboardHeader from "../../../../components/CommonComponents/DashboardHeader";
import LoaderApply from "../../../../components/CommonComponents/Loader/LoaderApply";
import UploadButton from "../../../../components/CustomComponents/UploadButton";
import { Navbar } from "../../../../components/DashboardComponents/Navbar/Navbar";
import useEnhanceResumeSocket from "../../../../hooks/useEnhanceResumeSocket";
import useS3Uploader from "../../../../hooks/useS3Uploader";
import { fetchCurrencies } from "../../../../redux/actions/jobActions";
import { updateJobSeeker } from "../../../../redux/actions/jobSeekerActions";
import "../ConfirmInformation/ConfirmInformation.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TurndownService from "turndown";
import useFileUpload from "../../../../hooks/useFileUpload";

const ConfirmInformation = () => {
  const turndownService = new TurndownService();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleUpload } = useS3Uploader();
  const { uploadStatus, uploadFile } = useFileUpload();
  const [previewVisible, setPreviewVisible] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const redirectUrl = useSelector((state) => state.auth.redirectUrl);
  const resumeJson = useSelector((state) => state?.auth?.user?.resume_json);
  const profilePic = useSelector((state) => state?.auth?.user?.profile_pic);
  const [enhancedData, setEnhancedData] = useState(resumeJson ?? {});
  const [isEnhanced, setIsEnhanced] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formDetails, setFormDetails] = useState(null);
  const location = useSelector((state) => state.location?.userCountry);
  const editableRef = useRef(null);
  const [currentSection, setCurrentSection] = useState("");
  const userId = useSelector((state) => state.auth?.user?.id);
  const userDetail = useSelector((state) => state.auth?.user);
  const url = `${websocketUrl}/jobseeker_profile_enhance/${userId}/`;
  const { messages, sendMessage, clearMessages, socketLoading } =
    useEnhanceResumeSocket(url);
  const [show, setShow] = useState(false);
  const [currentKey, setCurrentKey] = useState(null);
  const [showProfile, setShowProfile] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [loading, setLoading] = useState(false);
  const currencies = useSelector((state) => state.job.currencies);
  const [defaultCurrency, setDefaultCurrency] = useState("USD");
  const [showTextArea, setShowTextArea] = useState(false);
  const [
    showPhysicallyChallengedTextArea,
    setShowPhysicallyChallengedTextArea,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editedContent, setEditedContent] = useState("");
  const [textareaError, setTextareaError] = useState("");

  const updatedPayload = {
    ...enhancedData,
    candidate_location: userDetail?.address ?? resumeJson?.candidate_location,
    candidate_name: userDetail?.full_name ?? resumeJson.candidate_name,
    contact_details:
      userDetail?.country_code && userDetail?.phone
        ? userDetail?.phone
        : resumeJson?.contact_details,
    mail_ld: userDetail.email ?? resumeJson?.mail_ld,
  };

  const handleSkip = () => {
    if (redirectUrl) {
      navigate(redirectUrl);
    } else {
      navigate("/applicant-dashboard", { replace: true });
    }
  };
  const handleSaveAndProceed = (formDetails) => {
    if (imageUrl || profilePic) {
      setIsLoading(true);
      dispatch(
        updateJobSeeker(
          token,
          userId,
          userDetail?.resume,
          updatedPayload,
          imageUrl,
          formDetails
        )
      )
        .then(() => {
          setShowProfile(true);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error updating job seeker:", error);
          setIsLoading(false);
        });
    } else {
      setIsModalVisible(true);
    }
  };

  const handleConfirm = async () => {
    setIsModalVisible(false);
    setShowProfile(true);
    dispatch(
      updateJobSeeker(
        token,
        userId,
        userDetail?.resume,
        updatedPayload,
        imageUrl,
        formDetails
      )
    );
  };
  const handleFormSubmit = async (formDetails) => {
    setIsLoading(true);
    try {
      await dispatch(
        updateJobSeeker(
          token,
          userId,
          userDetail.resume,
          updatedPayload,
          imageUrl,
          formDetails
        )
      );

      toast.success("Profile completed successfully!");

      setIsLoading(false);

      if (redirectUrl) {
        navigate(redirectUrl);
      } else {
        navigate("/applicant-dashboard", { replace: true });
      }
    } catch (error) {
      console.error("Failed to update job seeker: ", error);
      setIsLoading(false);
      toast.error("Failed to complete the profile.");
    }
  };
  const mapCountryToCurrency = async (countryCode) => {
    try {
      const response = await axios.get(
        `https://restcountries.com/v3.1/alpha/${countryCode}`
      );

      const currencyCode = Object.keys(response.data[0].currencies)[0];

      return currencyCode;
    } catch (error) {
      console.error("Error fetching country information:", error);
    }
  };

  // const fetchUserLocationAndSetCurrency = async () => {
  //   if (location !== null && currencies === null) {
  //     try {
  //       const currencyCode = await mapCountryToCurrency(location);
  //       const currencySymbol = currencyCode;

  //       setDefaultCurrency(currencySymbol);
  //     } catch (error) {
  //       console.error("Error fetching location-based currency", error);
  //     }
  //   }
  // };

  const handleEnhanceClick = (key, data) => {
    setCurrentKey(key); // Update the currentKey to indicate which section is being enhanced
    sendMessage(data); // Send the data to be enhanced
    setIsEnhanced(true);
    setCurrentSection("");

    setTextareaError(""); // Clear the textarea error message when regenerating content

    if (editableRef.current) {
      const range = document.createRange();
      const sel = window.getSelection();
      setTimeout(() => {
        editableRef.current.focus();
        range.selectNodeContents(editableRef.current);
        range.collapse(false);
        sel.removeAllRanges();
        sel.addRange(range);
      }, 0);
    }
  };

  const handleShow = (key, content) => {
    setCurrentKey(key);
    setCurrentSection(content);
    const htmlContent = marked(content); // Convert markdown to HTML
    setEditedContent(htmlContent);
    setShow(true);
    setTextareaError("");
  };

  const handleClose = () => {
    setCurrentSection("");
    setShow(false);
    setIsEnhanced(false);
  };

  const handleSave = () => {
    if (!editedContent.trim()) {
      // Check if the content is just whitespace or empty
      setTextareaError("This field cannot be empty."); // Set the error message
      return; // Prevent further execution
    }

    const markdownContent = turndownService.turndown(editedContent);
    if (currentKey && markdownContent !== currentSection) {
      const updatedSections = enhancedData.sections.map((section) => {
        if (section.heading === currentKey) {
          return { ...section, content: markdownContent };
        }
        return section;
      });

      setEnhancedData({ ...enhancedData, sections: updatedSections });
      setCurrentSection(markdownContent);
      setTextareaError(""); // Clear any previous error messages
    } else {
    }

    setShow(false);
  };

  const handleEditableBlur = () => {
    if (editableRef.current) {
      const content = editableRef.current.innerText;
      if (content !== editedContent) {
        setEditedContent(content);
      }
    }
  };
  const handleRemove = () => {
    try {
      dispatch(
        updateJobSeeker(
          token,
          userId,
          userDetail.resume,
          updatedPayload,
          "",
          navigate
        )
      );
      setImageUrl("");
      // Show a success message
      toast.success("Image removed successfully.");
    } catch (error) {
      // Handle any errors
      toast.error("Failed to remove image.");
      console.error("Error removing image:", error);
    }
  };

  const handleCancel = () => setPreviewVisible(false);
  const handlePreview = () => setPreviewVisible(true);

  const handleChange = async (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setLoading(false);
      const file = info.file.originFileObj;

      const fileUrl = await uploadFile(file, token);
      // const fileUrl = uploadResults.get(file) || uploadResults[file];

      if (fileUrl) {
        toast.success("Profile photo uploaded successfully");

        setImageUrl(fileUrl);
      } else {
        toast.error("Error uploading the file");
      }
    } else if (info.file.status === "error") {
      setLoading(false);
      toast.error("Upload failed: An error occurred");
      setImageUrl(
        "https://haire.s3.ap-south-1.amazonaws.com/production/Group+1597884744.png"
      );
    }
  };

  useEffect(() => {
    if (show && editableRef.current) {
      editableRef.current.innerHTML = currentSection;
    }
  }, [show, currentSection]);

  useEffect(() => {
    if (currentSection) {
      const htmlContent = marked(currentSection); // Convert markdown to HTML
      setEditedContent(htmlContent);
    }
  }, [currentSection]);

  useEffect(() => {
    if (messages.length > 0 && messages.includes("<ENDOFTURN>")) {
      const enhancedMarkdown = messages
        .join("")
        .replace(/<ENDOFTURN>|<STARTOFTURN>/g, "");
      const enhancedHtml = marked(enhancedMarkdown); // Convert markdown to HTML
      setEditedContent(enhancedHtml); // Update React Quill content
      setEnhancedData((prevData) => {
        const updatedSections = prevData.sections.map((section) => {
          if (section.heading === currentKey) {
            return { ...section, content: enhancedHtml }; // Update the content of the matched section
          }
          return section; // Return other sections as they are
        });
        return { ...prevData, sections: updatedSections }; // Update the sections array in the resume data
      });
      clearMessages(); // Reset message queue
    }
  }, [messages]);


  const handleBack = () => {
    navigate("/resume-builder", { replace: true });
  };
  const closeModal = () => {
    setShowProfile(false);
  };
  const onCheck = (e) => {
    setShowTextArea(e.target.checked);
  };
  const onCheckShow = (e) => {
    setShowPhysicallyChallengedTextArea(e.target.checked);
  };

  useEffect(() => {
    if (token) {
      dispatch(fetchCurrencies(token));
    }
  }, []);

  return (
    <>
      <Navbar />
      <section className="confim_info_sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="confim_back_btn"></div>
              <div className="confim_info_sec_inner">
                <DashboardHeader title="" backButtonLink={handleBack} />
                <div className="confim_info_sec_inner_head">
                  <h6>Confirm your information</h6>
                </div>
                <div className="confim_info_sec_inner_body">
                  <div className="userprofiledata">
                    <div className="img-center">
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action={`${baseURL}job/mock-api/`}
                        beforeUpload={beforeUpload}
                        onPreview={handlePreview}
                        onChange={handleChange}
                        accept="image/jpeg, image/png"
                      >
                        {imageUrl || profilePic ? (
                          <img
                            src={imageUrl || profilePic}
                            alt="avatar"
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "contain",
                              padding: "10px",
                            }}
                          />
                        ) : (
                          <div style={{ display: "flex-column" }}>
                            <UploadButton loading={loading} />
                            {/* <p style={{ textAlign: "center" }}>
                              Upload your profile photo
                            </p> */}
                          </div>
                        )}
                      </Upload>

                      {!imageUrl && !profilePic && (
                        <h5 className="error">Upload Profile Picture</h5>
                       
                      )}
                      {(imageUrl || profilePic) && (
                        <div className="view_delete">
                          <Button
                            icon={<EyeOutlined />}
                            onClick={handlePreview}
                          ></Button>
                          <Button
                            icon={<DeleteOutlined />}
                            onClick={handleRemove}
                          ></Button>
                        </div>
                      )}
                      <AntModal
                        open={previewVisible}
                        footer={null}
                        onCancel={handleCancel}
                      >
                        <img
                          alt="Preview"
                          style={{ width: "100%" }}
                          src={imageUrl || profilePic}
                        />
                      </AntModal>
                    </div>
                    <div>
                      <h2>
                        {userDetail?.full_name ?? resumeJson.candidate_name}
                      </h2>
                      <h3>
                        {userDetail?.address ?? resumeJson?.candidate_location}
                      </h3>
                      <h3>{userDetail.email ?? resumeJson?.mail_ld}</h3>
                      <h3>
                        {" "}
                        {userDetail?.country_code && userDetail?.phone
                          ? userDetail?.phone
                          : resumeJson?.contact_details}{" "}
                      </h3>
                    </div>
                  </div>

                  {enhancedData?.sections?.map((info, index) => {
                    const sectionTitle = info?.heading;
                    const sectionContent = info?.content ?? "";
                    return (
                      <div className="confim_info_single" key={index}>
                        <>
                          <div className="confim_info_single_head">
                            <h5>
                              {sectionTitle.charAt(0).toUpperCase() +
                                sectionTitle.slice(1)}
                            </h5>
                            <button
                              type="button"
                              onClick={() => {
                                handleShow(sectionTitle, sectionContent);
                              }}
                            >
                              <img src={sum_img} alt="" />
                            </button>
                          </div>
                          <div className="confim_info_single_body">
                            {sectionTitle !== "projects" ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: marked(sectionContent),
                                }}
                              ></div>
                            ) : (
                              <ul>
                                {sectionContent.map((project, projectIndex) => (
                                  <li key={projectIndex}>{project.name}</li>
                                ))}
                              </ul>
                            )}
                          </div>{" "}
                        </>
                      </div>
                    );
                  })}
                  <div className="login_btn_blue_new">
                    <button
                      type="button"
                      disabled={isLoading}
                      onClick={handleSaveAndProceed}
                      // disabled={!imageUrl && !profilePic}
                    >
                      {/* Save & Proceed */}
                      {isLoading ? (
                        <span
                          style={{
                            display: "inline-block",
                            padding: "0px 12px", // Adjust padding as needed
                            position: "relative",
                          }}
                        >
                          <LoaderApply color="#007BFF !important" />
                        </span>
                      ) : (
                        "Continue"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={showProfile}
        onHide={closeModal}
        className="report_modal_sec modal-wd"
        size="xl"
        backdrop="static"
      >
        <Modal.Body className="pading_modal">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h3 className="modal_heading">Complete your profile details</h3>
              <div className="form_sec_new_inner_first_innwer">
                <Form
                  name="profileDetailsForm"
                  onFinish={handleFormSubmit}
                  // initialValues={{ currency: defaultCurrency }}
                  className="job_details_from completeProfile"
                >
                  <div className="row">
                    <label>
                      Annual Compensation:
                      <span className="custom_astrix">*</span>
                    </label>
                    <div className="col-lg-2 col-md-4 col-sm-4 text-start years_exp">
                      <Form.Item
                        name="currency"
                        initialValue={defaultCurrency}
                        rules={[
                          {
                            required: true,
                            message: "Please select a currency",
                          },
                        ]}
                      >
                        <select
                          placeholder="Select Currency"
                          className="CurrencyType custom_select"
                          defaultValue={defaultCurrency}
                        >
                          {Object.entries(currencies).map(([key, value]) => (
                            <option
                              id="currency"
                              key={key}
                              value={key}
                            >{` ${value}`}</option>
                          ))}
                        </select>
                      </Form.Item>
                    </div>
                    <div className="col-lg-10 col-md-8 col-sm-8 text-start years_exp">
                      <Form.Item
                        name="current_ctc"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the starting salary",
                          },
                          {
                            type: "number",
                            min: 0.01, // Ensures the value is greater than 0
                            transform(value) {
                              return value ? Number(value) : undefined;
                            },
                            message:
                              "The salary must be a positive number greater than 0",
                          },
                        ]}
                      >
                        <Input
                          placeholder="E.g., 50000"
                          type="number"
                          min="0" // HTML5 native validation to ensure the input is greater than 0
                          step="any" // Allows entering decimal values
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <Checkbox
                        checked={showTextArea}
                        onChange={onCheck}
                        className="checkbox-flex"
                      >
                        Would you like to provide your compensation structure?
                      </Checkbox>

                      {showTextArea && (
                        <Form.Item
                          name="ctc_options"
                          rules={[
                            { required: true, message: "Details are required" },
                          ]}
                        >
                          <Input.TextArea
                            style={{
                              height: 200,
                              resize: "none",
                              marginTop: "10px",
                            }}
                            className="textarea_generator"
                            placeholder="Basic Salary
                        Allowances (Housing, Transport, Meal, etc.)
                        Incentives/Bonuses
                         Medical Allowance
                         Provident Fund
                         Gratuity
                         Other Benefits (Insurance, Stock Options, etc.)"
                            rows={4}
                          />
                        </Form.Item>
                      )}
                    </div>
                  </div>

                  <div className="row mt-1 mb-1">
                    <div className="col">
                      <Checkbox
                        checked={showPhysicallyChallengedTextArea}
                        onChange={onCheckShow}
                        className="checkbox-flex"
                      >
                        Assistance Benefits
                      </Checkbox>

                      {showPhysicallyChallengedTextArea && (
                        <Form.Item
                          name="physically_challenged"
                          rules={[
                            { required: true, message: "Details are required" },
                          ]}
                        >
                          <Input.TextArea
                            style={{
                              height: 200,
                              resize: "none",
                              marginTop: "10px",
                            }}
                            className="textarea_generator"
                            // placeholder=""
                            rows={4}
                          />
                        </Form.Item>
                      )}
                    </div>
                  </div>

                  <Form.Item
                    name="notice_period"
                    label="Resignation Notice"
                    rules={[
                      {
                        required: true,
                        message: "Please select!",
                      },
                    ]}
                  >
                    <Radio.Group className="radio">
                      <Radio.Button value={1} className="num0femp">
                        15 Days or less
                      </Radio.Button>
                      <Radio.Button value={2} className="num0femp">
                        1 Month
                      </Radio.Button>
                      <Radio.Button value={3} className="num0femp">
                        2 Months
                      </Radio.Button>
                      <Radio.Button value={4} className="num0femp">
                        3 Months
                      </Radio.Button>
                      <Radio.Button value={5} className="num0femp">
                        More than 3 Months
                      </Radio.Button>
                      <Radio.Button value={6} className="num0femp">
                        Serving Resignation Period
                      </Radio.Button>
                      <Radio.Button value={7} className="num0femp">
                        Immediately
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item>
                    <div className="button_profile">
                      <button className="transp" onClick={handleSkip}>
                        Skip for now
                      </button>
                      <button disabled={isLoading}>
                        {isLoading ? (
                          <span
                            style={{
                              display: "inline-block",
                              padding: "0px 12px", // Adjust padding as needed
                              position: "relative",
                            }}
                          >
                            <LoaderApply color="#007BFF" />
                          </span>
                        ) : (
                          "Save & Proceed"
                        )}
                      </button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={handleClose} className="summary_sec_new">
        <button onClick={handleClose} className="close_icn_new">
          <i className="fas fa-times"></i>
        </button>
        <div className="summary_sec_new_inner">
          {enhancedData?.sections?.map((section, index) => {
            const sectionTitle = section?.heading;
            const sectionContent = section?.content;
            const order_id = section?.order_id;

            if (sectionTitle === currentKey) {
              const customSection = {
                heading: sectionTitle,
                content:
                  currentSection === "" || currentSection === null
                    ? sectionContent
                    : currentSection,
                order_id: order_id,
              };
              return (
                <div className="summary_sec_new_inner_cont" key={index}>
                  <ReactQuill
                    theme="snow"
                    value={editedContent}
                    onChange={setEditedContent}
                    modules={ConfirmInformation.modules}
                    formats={ConfirmInformation.formats}
                    bounds={".app"}
                    placeholder="Enter text here..."
                  />
                 
                  {textareaError && (
                    <div style={{ color: "#d30f0f", paddingTop: "10px" }}>
                      {textareaError}
                    </div>
                  )}
                  {socketLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <ThreeCircles
                        visible={true}
                        height="20"
                        width="20"
                        color="#1865c1"
                        ariaLabel="loading-indicator"
                      />
                    </div>
                  ) : (
                    <div className="save_rege_sec_new" id="save_reg">
                      <button className="save_btn_new" onClick={handleSave}>
                        Save
                      </button>
                      <button
                        className="sync_btn_new"
                        onClick={() =>
                          handleEnhanceClick(sectionTitle, customSection)
                        }
                      >
                        <i className="fas fa-sync-alt"></i>Regenerate
                      </button>
                    </div>
                  )}
                </div>
              );
            }
            return null;
          })}
        </div>
      </Modal>
      <CustomPopup
        isModalVisible={isModalVisible}
        handleCancel={() => {
          setIsModalVisible(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
        handleConfirm={handleConfirm}
        question={"Are you sure you want to proceed without a profile picture?"}
      />
    </>
  );
};

export default ConfirmInformation;

function resizeImage(file, maxWidth, maxHeight, callback) {
  const reader = new FileReader();
  reader.onload = (event) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);

      ctx.canvas.toBlob(
        (blob) => {
          const resizedFile = new File([blob], file.name, {
            type: "image/jpeg",
            lastModified: Date.now(),
          });
          callback(resizedFile);
        },
        "image/jpeg",
        0.7
      ); // Adjust quality as needed
    };
    img.src = event.target.result;
  };
  reader.readAsDataURL(file);
}

const beforeUpload = (file) => {
  const isImage = file.type.startsWith("image/");
  if (!isImage) {
    toast.error("You can only upload image files!");
    return false; // Stop the upload
  }

  const isLessThan10MB = file.size / 1024 / 1024 < 10; // Change the size limit as needed
  if (!isLessThan10MB) {
    toast.error("Image must be smaller than 10MB!"); // Customize the error message
    return false; // Stop the upload
  }
  return new Promise((resolve, reject) => {
    resizeImage(file, 1024, 1024, (resizedFile) => {
      resolve(resizedFile);
    });
  });
};

export const CustomPopup = ({
  isModalVisible,
  handleCancel,
  handleConfirm,
  question,
}) => {
  return (
    <PopUp
      centered
      open={isModalVisible}
      closable={false}
      footer={[
        <div className="inter_panel_ready_sec_inner_btns">
          <button type="button" className="no_btn_int" onClick={handleCancel}>
            No
          </button>
          <button type="button" className="yes_btn_int" onClick={handleConfirm}>
            Yes
          </button>
        </div>,
      ]}
      width={800}
    >
      <div className="inter_panel_ready_sec_inner">
        {/* <img src={close_job} alt="" /> */}
        <h6>{question}</h6>
      </div>
    </PopUp>
  );
};

ConfirmInformation.modules = {
  toolbar: [["bold", "italic"], [{ list: "bullet" }]],
};

// Quill formats to allow
ConfirmInformation.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
];
