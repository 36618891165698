// useResponsibilitiesGenerator.js
import { useEffect, useState } from 'react';
import instance from '../api/instance';
import { useSelector } from 'react-redux';

const useSuggestionsGenerator = ({ type, previousSuggestions, keyword, experience, jobProfile }) => {
    const [suggestions, setSuggestions] = useState([]);
    const token = useSelector((state) => state.auth.token);

    useEffect(() => {
        const generateResponsibilities = async () => {
            if (keyword?.trim() !== "") {
                try {
                    const response = await instance.post(
                        '/ai/keyword_generate/',
                        {
                            type: type,
                            previous: previousSuggestions ?? "",
                            keyword: keyword ?? "",
                            experience: experience ?? "",
                            job_profile: jobProfile ?? ""
                        },
                        {
                            headers: {
                                Authorization: `Token ${token}`,
                              },
                        }
                    );

                    // Assuming the response includes a list of responsibilities
                    const newResponsibilities = response.data.newkeyword;

                    setSuggestions(newResponsibilities);
                } catch (error) {
                    console.error('Error generating responsibilities:', error);
                }
            } else {
                setSuggestions([]); // Clear responsibilities if keyword is empty
            }
        };

        generateResponsibilities();
    }, [keyword, experience, previousSuggestions]); // Only re-run the effect if keyword, experience, or previousResponsibilities change

    return suggestions;
};

export default useSuggestionsGenerator;
