import { create } from "zustand";
import * as Yup from "yup";
// @ts-ignore
import instance from "../api/instance";

interface CustomPlanFormData {
  fullName: string;
  email: string;
  phone: string;
}

interface FormErrors {
  [key: string]: string | undefined;
}

interface StoreState {
  formData: CustomPlanFormData;
  formErrors: FormErrors;
  error: string | null;
  loading: boolean;
  setFormData: (name: keyof CustomPlanFormData, value: string) => void;
  validateForm: () => Promise<boolean>;
  validateField: (name: keyof CustomPlanFormData, value: string) => void;
  submitCustomPlan: () => Promise<void>;
}

const schema = Yup.object<CustomPlanFormData>({
  fullName: Yup.string()
    .trim()
    .matches(/^[a-zA-Z\s]*$/, "Full name must contain only letters and spaces")
    .max(30, "Full name must be no longer than 30 characters")
    .required("Please fill out your full name"),
  email: Yup.string()
    .email("Invalid email address")
    .matches(
      /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
      "Please use valid email address"
    )
    .required("Email is required"),
  phone: Yup.string()
    .trim()
    .matches(/^\+?\s?\d+(\s|\d|\(|\)|-)*$/, "Invalid contact number format")
    .min(10, "Contact number should be at least 10 digits")
    .max(20, "Contact number should not exceed 20 characters")
    .required("Contact is required"),
});

const customPlanStore = create<StoreState>((set, get) => ({
  formData: {
    fullName: "",
    email: "",
    phone: "",
  },
  formErrors: {},
  error: null,
  loading: false,

  setFormData: (name, value) =>
    set((state) => ({
      formData: { ...state.formData, [name]: value },
    })),

  validateForm: async () => {
    try {
      await schema.validate(get().formData, { abortEarly: false });
      set({ formErrors: {} });
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = err.inner.reduce<FormErrors>(
          (acc, { path, message }) => {
            if (path) {
              acc[path as keyof CustomPlanFormData] = message;
            }
            return acc;
          },
          {}
        );
        set({ formErrors: errors });
      }
      return false;
    }
  },

  validateField: async (name: keyof CustomPlanFormData, value: string) => {
    try {
      await schema.validateAt(name, { ...get().formData, [name]: value });
      set({ formErrors: { ...get().formErrors, [name]: undefined } });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        set({ formErrors: { ...get().formErrors, [name]: error.message } });
      }
    }
  },

  submitCustomPlan: async () => {
    set({ loading: true, error: null });

    const { fullName, email, phone } = get().formData;

    try {
      await instance.post("/subscription/send-custom_plan_details/", {
        full_name: fullName,
        email,
        phone,
      });

      // Clear form data on success
      set({
        formData: {
          fullName: "",
          email: "",
          phone: "",
        },
        error: null,
      });
    } catch (error) {
      if (error instanceof Error) {
        set({ error: error.message });
      }
    } finally {
      set({ loading: false });
    }
  },
}));

export default customPlanStore;
